import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 347.28"
    >
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M121.35 118.09h260.64v-31.3c.73-7.76-4.67-9.69-11.24-9.98-3.77-.18-7.97-.2-11.76-.01H208.82c-36.03 0-43.17-19.04-49.96-37.13-3.87-10.32-7.56-20.17-22.65-20.17H31.83c-6.76 0-12.33 5.57-12.33 12.33v253.04l61.44-139.95c6.5-14.82 24.13-26.83 40.41-26.83zm280.14 0h86.05c19.11 0 29.72 16.28 21.83 34.29l.03.01-73.77 168.06c-6.5 14.83-24.14 26.83-40.41 26.83H29.03c-7.65 0-13.84-2.56-18.08-6.74C4.75 335.35 0 323.41 0 315.49V31.83C0 14.31 14.31 0 31.83 0h104.38c28.52 0 34.55 16.06 40.86 32.89 4.46 11.89 9.15 24.4 31.75 24.4 51.2 0 102.48.33 153.67.02 3.05-.03 6.18-.06 9.1.07 16.41.76 30.82 5.4 29.88 29.41l.02 31.3zm85.97 19.05H121.27c-8.66 0-19 7.42-22.44 15.25L25.06 320.45l-.03-.01c-2.05 4.68-1.46 7.8 4.07 7.8h366.2c8.66 0 18.99-7.43 22.43-15.26l73.77-168.06c.07.03 4.09-7.78-4.04-7.78z"
      ></path>
    </svg>
  );
}

export default Icon;
