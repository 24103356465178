import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1"
      viewBox="0 0 24 24"
    >
      <g fill="#fff">
        <path d="M21.7 4.9l-2.6-2.6c-.4-.4-1-.4-1.4 0L16 4l4 4 1.7-1.7c.4-.4.4-1.1 0-1.4z"></path>
        <path d="M15 5.1L2 18 2 22 6 22 18.9 9z"></path>
      </g>
    </svg>
  );
}

export default Icon;
