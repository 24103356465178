import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 100 100">
      <path
        fill="#fff"
        d="M82.6 39.8c-.5-1.8-1.3-3.7-2.3-5.7 1.9-2.6 4.1-5.2 6-8.1.6-.8.6-1.7 0-2.6-2.4-3.5-6.4-7.1-9.7-9.9-.8-.8-1.9-.8-2.8-.1l-8.4 6.2c-1.6-.8-3.5-1.5-5.2-2.2L58.6 6.8c-.1-1.1-.9-1.8-2.1-1.8h-13c-1.2 0-2 .7-2.2 1.7-.8 3.4-1.2 7.3-1.6 10.7-1.8.6-3.6 1.3-5.2 2.3l-8.1-6.2c-.5-.4-1.2-.7-1.6-.7-2 0-9.8 8.7-11.3 10.6-.8 1-.7 1.6.1 2.7 2.1 2.8 4.2 5.4 6.2 8.1-.9 1.9-1.6 3.4-2.2 5.4l-11 1.6c-.9.2-1.6 1.3-1.6 2.3v12.9c0 1 .7 1.9 1.7 2.1l10.6 1.7c.5 1.8 1.4 3.7 2.3 5.6-1.9 2.7-4 5.2-6 8.1-.6.9-.5 1.8 0 2.6 2.5 3.5 6.4 7.1 9.7 10 .9.8 1.9.8 2.8.2l8.4-6.2c1.7.8 3.5 1.5 5.2 2.1l1.6 10.7c.1.9 1 1.8 2.2 1.8h13c1.2 0 1.8-.7 2.1-1.7.9-3.4 1.3-7.3 1.7-10.7 1.8-.6 3.6-1.4 5.3-2.2l8.1 6.3c.5.4 1.1.5 1.6.5 1.9 0 9.8-8.8 11.3-10.7.6-.8.5-1.6-.2-2.6-2.1-2.9-4.2-5.5-6.2-8.4.9-1.6 1.6-3.2 2.3-5.2l10.7-1.6c1-.1 1.7-1.2 1.7-2V43.6c0-.9-.7-1.9-1.7-2.2l-10.6-1.6zM49.9 65C41.7 65 35 58.3 35 50.1 35 41.7 41.7 35 49.9 35c8.3 0 15 6.7 15 15.1 0 8.2-6.7 14.9-15 14.9z"
      ></path>
    </svg>
  );
}

export default Icon;
