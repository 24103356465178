import React from "react";

function NoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
    >
      <g fill="#fff" fillOpacity="1">
        <path d="M49.679 41.5l-5.596 8.04-3.949-3.241a.999.999 0 10-1.268 1.545l4.786 3.929a.995.995 0 00.771.217c.276-.038.524-.19.684-.419l6.214-8.929a1 1 0 10-1.642-1.142z"></path>
        <path d="M45.5 34.051V7a1 1 0 00-1-1h-8V4a1 1 0 00-1-1h-6V1a1 1 0 00-1-1h-9a1 1 0 00-1 1v2h-6a1 1 0 00-1 1v2h-8a1 1 0 00-1 1v50a1 1 0 001 1h34.104c2.002 1.26 4.362 2 6.896 2 7.168 0 13-5.832 13-13 0-6.831-5.299-12.436-12-12.949zM20.5 2h7v4h-7V2zm-7 3h5v2a1 1 0 001 1h9a1 1 0 001-1V5h5v4h-21V5zm-9 51V8h7v2a1 1 0 001 1h23a1 1 0 001-1V8h7v26.038c-.057.004-.112.016-.169.021-.195.018-.387.046-.579.073-.229.031-.459.062-.684.104-.193.037-.381.083-.571.128a12.7 12.7 0 00-.652.168c-.187.055-.37.118-.553.181-.21.072-.418.146-.623.229-.179.072-.355.15-.531.23a11.743 11.743 0 00-1.096.562 12.072 12.072 0 00-1.032.66c-.177.125-.349.255-.519.389-.15.118-.298.237-.442.361-.165.142-.325.289-.482.439-.137.13-.273.261-.405.397-.152.157-.297.32-.441.485-.124.142-.248.283-.365.43-.138.172-.267.35-.396.529-.109.152-.22.302-.323.459-.123.187-.235.38-.349.573-.093.16-.189.317-.276.48-.107.203-.202.412-.299.62-.076.165-.157.327-.227.496-.09.219-.167.444-.245.668-.059.167-.123.332-.175.502-.073.24-.129.486-.188.731-.039.163-.086.322-.12.487-.055.275-.091.557-.128.838-.019.142-.047.28-.062.423a12.429 12.429 0 00-.002 2.593c.016.162.048.318.07.479.036.263.068.526.12.785.035.175.085.345.127.518.058.239.112.479.184.714.049.159.11.313.165.47.084.243.167.487.266.724.055.133.12.26.18.39.115.254.232.507.363.753.058.107.123.21.184.315.148.259.298.515.464.763.061.091.128.177.191.267.176.25.356.498.551.736.072.088.15.17.224.256.155.18.303.364.468.536H4.5zm40 2a10.94 10.94 0 01-7.079-2.593c-.108-.091-.21-.19-.314-.286a11.053 11.053 0 01-.911-.929 10.468 10.468 0 01-.468-.583c-.096-.127-.195-.251-.286-.382a11.15 11.15 0 01-.615-.993c-.043-.08-.077-.165-.118-.245a11.14 11.14 0 01-.403-.874c-.052-.13-.097-.263-.145-.396a11.516 11.516 0 01-.363-1.214 10.896 10.896 0 01-.163-.865c-.019-.125-.043-.248-.057-.375A11.29 11.29 0 0133.5 47c0-6.065 4.935-11 11-11s11 4.935 11 11-4.935 11-11 11z"></path>
        <path d="M9.5 18h13a1 1 0 100-2h-13a1 1 0 100 2z"></path>
        <path d="M22.5 46h-13a1 1 0 100 2h13a1 1 0 100-2z"></path>
        <path d="M9.5 24h29a1 1 0 100-2h-29a1 1 0 100 2z"></path>
        <path d="M9.5 36h24a1 1 0 100-2h-24a1 1 0 100 2z"></path>
        <path d="M28.5 40h-19a1 1 0 100 2h19a1 1 0 100-2z"></path>
        <path d="M9.5 30h14a1 1 0 100-2h-14a1 1 0 100 2z"></path>
        <path d="M38.5 28h-7a1 1 0 100 2h7a1 1 0 100-2z"></path>
        <path d="M26.79 28.29c-.19.189-.29.449-.29.71 0 .26.1.52.29.71a1.011 1.011 0 001.42 0c.18-.19.29-.45.29-.71 0-.261-.11-.521-.29-.71-.37-.36-1.04-.37-1.42 0z"></path>
      </g>
    </svg>
  );
}

export default NoteIcon;
