import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      version="1.1"
      viewBox="0 0 32 32"
    >
      <path fill="#fff" fillOpacity="1" d="M11 10h2v16h-2z"></path>
      <path fill="#fff" fillOpacity="1" d="M15 10h2v16h-2z"></path>
      <path fill="#fff" fillOpacity="1" d="M19 10h2v16h-2z"></path>
      <path fill="#fff" fillOpacity="1" d="M3 4h26v2H3z"></path>
      <path
        fill="#fff"
        fillOpacity="1"
        d="M21 4h-2V3c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v1h-2V3c0-1.7 1.3-3 3-3h4c1.7 0 3 1.3 3 3z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        d="M22 32H10c-1.6 0-3-1.3-3.2-2.9L5 5.1l2-.2 1.8 24c0 .6.6 1.1 1.2 1.1h12c.6 0 1.1-.5 1.2-1.1l1.8-24 2 .2-1.8 24C25 30.7 23.6 32 22 32z"
      ></path>
    </svg>
  );
}

export default Icon;
