import './login.css';
import { Config } from './config'
import { useState, createRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

function Login({setCookie}) {
  const [errorMsg, setErrorMsg] = useState();
  const [register, setRegister] = useState(false);
  const recaptchaRef = createRef();

  function doUser(endpoint) {
    var o = {
      email: document.getElementById('loginEmail').value,
      password: document.getElementById('loginPass').value,
    };

    if (recaptchaRef.current) {
      o.token = recaptchaRef.current.getValue();
    }

    fetch(`${Config.serverAddr}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(o)
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        setCookie('session', data.session, {
          expires: new Date(data.expires * 1000),
          path: '/',
          domain: '.'+Config.domain
        });
      } else {
        switch (data.error) {
        case 'exists':
          setErrorMsg('An account with this e-mail already exists');
          break;
        case 'emailinvalid':
          setErrorMsg('E-mail address entered is invalid');
          break;
        case 'passwordshort':
          setErrorMsg('Password is too short');
          break;
        case 'notexists':
          setErrorMsg('Username or password invalid');
          break;
        case 'captcha':
          setErrorMsg('Captcha verification failed');
          break;
        default:
          setErrorMsg(`Unknown error`);
          break;
        }
      }
    })
    .catch((error) => console.error(error));
  }

  function checkInputs() {
    if (!document.getElementById('loginEmail').value || !document.getElementById('loginPass').value) {
      setErrorMsg('Please enter an e-mail and password');
      return false;
    } else {
      return true;
    }
  }

  function handleKeyPress(e) {
    if (e.which == 13) {
      e.preventDefault();
      document.getElementById('confirmBtn').click();
    }
  }

  function doLogin() {
    if (checkInputs()) {
      doUser('/login');
    }
  }

  function doRegister() {
    if (checkInputs()) {
      if (document.getElementById('loginPass').value != document.getElementById('loginPassConfirm').value) {
        setErrorMsg('Passwords do not match');
      } else {
        doUser('/register', null);
      }
    }
  }

  function switchToRegister() {
    setErrorMsg(null);
    setRegister(true);
  }

  function switchToLogin() {
    setErrorMsg(null);
    setRegister(false);
  }

  function LoginPage() {
    return (
      <div className='loginContainer'>
        <div className='loginPage'>
          <div className='loginTitle'>Login to {Config.siteName}</div>
          <div className='loginTable'>
            <div className='loginRow'>
              <div className='loginCell'>
                E-mail
              </div>
              <div className='loginCell'>
                <input type="email" id="loginEmail" onKeyPress={handleKeyPress} />
              </div>
            </div>
            <div className='loginRow'>
              <div className='loginCell'>
                Password
              </div>
              <div className='loginCell'>
                <input type="password" id="loginPass" onKeyPress={handleKeyPress} />
              </div>
            </div>
          </div>
          <div className='loginError'>{errorMsg}</div>
          <div className='loginButtons'>
            <button id="confirmBtn" onClick={doLogin}>Login</button>
            <button onClick={switchToRegister}>Sign Up</button>
          </div>
        </div>
      </div>
    );
  }

  function RegisterPage() {
    return (
      <div className='loginContainer'>
        <div className='loginPage'>
          <div className='loginTitle'>Sign up to {Config.siteName}</div>
          <div className='loginTable'>
            <div className='loginRow'>
              <div className='loginCell'>
                E-mail
              </div>
              <div className='loginCell'>
                <input type="email" id="loginEmail" onKeyPress={handleKeyPress} />
              </div>
            </div>
            <div className='loginRow'>
              <div className='loginCell'>
                Password
              </div>
              <div className='loginCell'>
                <input type="password" id="loginPass" onKeyPress={handleKeyPress} />
              </div>
            </div>
            <div className='loginRow'>
              <div className='loginCell'>
                Confirm Password
              </div>
              <div className='loginCell'>
                <input type="password" id="loginPassConfirm" onKeyPress={handleKeyPress} />
              </div>
            </div>
          </div>
          <div className='loginCaptcha'>
            <ReCAPTCHA ref={recaptchaRef} sitekey={Config.recaptchaKey} />
          </div>
          <div className='loginError'>{errorMsg}</div>
          <div className='loginButtons'>
            <button onClick={doRegister}>Sign Up</button>
            <button onClick={switchToLogin}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }

  return (!register ? <LoginPage /> : <RegisterPage />);
}

export default Login;
